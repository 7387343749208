// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-projects-js": () => import("./../../../src/pages/personal-projects.js" /* webpackChunkName: "component---src-pages-personal-projects-js" */),
  "component---src-pages-skills-and-experience-js": () => import("./../../../src/pages/skills-and-experience.js" /* webpackChunkName: "component---src-pages-skills-and-experience-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-hippogriff-js": () => import("./../../../src/templates/hippogriff.js" /* webpackChunkName: "component---src-templates-hippogriff-js" */)
}

